import { useMemo, useRef, useState } from 'react'
import { useSetAtom, useAtomValue } from 'jotai'
import { setCueAtom, setCuesAtom, setCellsAtom, setCueOrderAtom } from '../../store/rundown.store.js'
import { runnerAtom, momentAtom } from '../../store/runner.store.js'
import PropTypes from 'prop-types'
import { CueLoadingProvider } from './CueLoadingProvider.jsx'
import CueCells from './CueCells.jsx'
import CueItemSettings from './CueItemSettings.jsx'
import CueItemIndex from './CueItemIndex.jsx'
import CueItemHeader from './CueItemHeader.jsx'
import CueItemTitle from './CueItemTitle.jsx'
import ScrollIntoView from './ScrollIntoView.jsx'
import TimeInput from './TimeInput.jsx'
import * as cueState from '../../constants/cueStates.js'
import { CUE_TYPE_CUE, CUE_TYPE_GROUP, CUE_TYPE_HEADING } from '../../constants/cueTypes.js'
import { addRundownCue, duplicateRundownCue, deleteRundownCue, setNextCueId, updateRundownCue, startRunnerTimesnap } from '../../firestore.js'
import { floorSeconds, formatDuration, formatDurationHuman, formatTimeOfDay } from '../../utils/formatTime.js'
import { Tooltip, TooltipTrigger, TooltipContent } from '../interactives/Tooltip.jsx'

// KEEP ME - this empty array is used to ensure no re-renders between changes
const empty = []

export default function CueItem ({
  rundownId,
  index,
  className = '',
  runnerId,
  timezone,
  timestamp,
  cue,
  cells = empty,
  columns = empty,
  isNext = { isNext },
  hiddenColumns,
  readonly,
  onDragStart = () => {},
  onDragOver = () => {},
  onDrop = () => {},
  dragging = false,
  dragover = false,
  setRundownColoursModalOpen,
  cueBackgroundColours,
  parentId = null,
  handleItemCollapse = () => {},
  collapsedGroups = empty,
  lastSibling = false,
  childrenCues = empty,
  cuesTypesMap,
  draggingGroup = false,
  parentBackgroundColour = '',
  autoAddGroup = false,
  running = false,
}) {
  const [loading, setLoading] = useState(false)
  const [draggable, setDraggable] = useState(true)
  const setRunner = useSetAtom(runnerAtom)
  const setCueOrder = useSetAtom(setCueOrderAtom)
  const setCue = useSetAtom(setCueAtom)
  const setCues = useSetAtom(setCuesAtom)
  const setCells = useSetAtom(setCellsAtom)
  const moment = useAtomValue(momentAtom)

  const cueWrapper = useRef()

  const endDelta = timestamp ? floorSeconds(timestamp.duration - timestamp.elapsed) : 0

  const groupLineColour = (parentBackgroundColour && parentBackgroundColour !== 'transparent') ? parentBackgroundColour : null

  const isTypeCue = useMemo(()=> cue.type === CUE_TYPE_CUE, [cue.type])
  const isActive = useMemo(()=> isTypeCue && timestamp?.state === cueState.CUE_STATE_ACTIVE, [isTypeCue, timestamp?.state])
  const cellStyle = useMemo(() => {
    const userBgColor = cue.backgroundColor === 'transparent' ? undefined : cue.backgroundColor
    const backgroundColor = isTypeCue
      ? (isActive ? '#ef4444' : (userBgColor || '#161616'))
      : (userBgColor || 'transparent')
    return {
      transition: 'ease 0.3s background-color',
      backgroundColor,
      borderRadius: '0.25rem',
    }
  }, [isTypeCue, isActive, cue.backgroundColor])

  async function setNextCue () {
    setLoading(true)
    let nextCueId = cue.id
    if (cuesTypesMap[cue.id] === CUE_TYPE_GROUP) {
      nextCueId = childrenCues.filter((c) => c.type === CUE_TYPE_CUE)[0]?.id
    }
    if (!nextCueId) return setLoading(false)
    return setNextCueId(rundownId, runnerId, nextCueId)
      .then(({ data }) => setRunner(data))
      .finally(() => setLoading(false))
  }

  async function jumpToCue () {
    setLoading(true)
    let nextCueId = cue.id
    if (cuesTypesMap[cue.id] === CUE_TYPE_GROUP) {
      nextCueId = childrenCues.filter((c) => c.type === CUE_TYPE_CUE)[0]?.id
    }
    if (!nextCueId) return setLoading(false)
    return await startRunnerTimesnap(rundownId, runnerId, nextCueId)
      .then(({ data }) => setRunner(data))
      .finally(() => setLoading(false))
  }

  async function updateCue (payload) {
    setLoading(true)
    return updateRundownCue(rundownId, cue.id, payload)
      .then(({ data }) => setCue(data))
      .finally(() => setLoading(false))
  }

  async function addCue ({ position = 'after', payload } = {}) {
    setLoading(true)
    return addRundownCue(rundownId, { [position]: cue.id }, payload)
      .then(({ data: [rundown, cues] }) => {
        setCueOrder(rundown.cues)
        setCues(cues)
      })
      .finally(() => setLoading(false))
  }

  async function duplicateCue () {
    setLoading(true)
    return duplicateRundownCue(rundownId, cue.id)
      .then(({ data: [rundown, cue, cells] }) => {
        setCueOrder(rundown.cues)
        setCue(cue)
        setCells(cells)
      })
      .finally(() => setLoading(false))
  }

  async function deleteCue () {
    setLoading(true)
    return deleteRundownCue(rundownId, cue.id)
      .finally(() => setLoading(false))
  }

  // <TimeInput> returns a { time, diff } object, we only need the diff
  async function handleUpdateEndTime ({ diff }) {
    const duration = Math.max(cue.duration + diff, 0)
    return updateCue({ duration })
  }

  /**
   * Only propagate this event if the mouse is over an element with `data-draghandle`
   * @param  {DragEvent} event
   * @return {void}
   */
  function onDragStartInterceptor (event) {
    const handles = Array.from(event.target.querySelectorAll('[data-draghandle]'))
    if (!handles.some(target => _mouseInside(target, event))) return event.preventDefault()
    onDragStart(event)
  }

  /**
   * This avoids the conflict between `draggable` and `contenteditable` by removing the draggable attribute
   * when the focus is on a contenteditable field
   * @param  {MouseEvent} event
   * @return {void}
   */
  function contenteditableFix (event) {
    if (event.target.closest('[contenteditable]')) return setDraggable(false)
    else return setDraggable(true)
  }

  const cueCellsMemo = useMemo(() => (
    <CueLoadingProvider
      cueId={cue.id}
      className="flex items-stretch gap-1"
    >
      <CueCells
        cue={cue}
        cells={cells}
        columns={columns}
        hiddenColumns={hiddenColumns}
        cellStyle={cellStyle}
        readonly={readonly}
      />
    </CueLoadingProvider>
  ), [cue, cells, columns, hiddenColumns, cellStyle])

  return (
    <section
      ref={cueWrapper}
      id={cue.id}
      className={[
        'relative py-3 px-2 transition-[margin,opacity]',
        (dragging ? 'opacity-50' : ''),
        className,
      ].join(' ')}
      data-dropzone
      onDragOver={onDragOver}
      onDrop={onDrop}
    >
      {isActive ? <ScrollIntoView /> : ''}
      {/* Dropzone Indicator */}
      {!draggingGroup ? <div
        className={[
          'absolute pointer-events-none left-[2.5rem] max-w-4xl w-4/5 -top-3 border-b-2 h-4 border-blue-500 bg-black duration-300',
          (dragover && cue.id !== autoAddGroup ? 'z-20 opacity-100' : 'z-[-10] opacity-0'),
          (parentId ? 'ml-10' : 'ml-0'),
        ].join(' ')}
      ></div> : ''}

      {/* Group tree lines */}
      {(parentId || cue.type === CUE_TYPE_GROUP) &&
        <>
          {/* Top */}
          <div
            style={{
              backgroundColor: groupLineColour,
            }}
            className={[
              'w-[4px] absolute top-0 left-[20px]',
              groupLineColour ? 'brightness-150' : 'bg-gray-700',
              [CUE_TYPE_CUE, CUE_TYPE_HEADING].includes(cue.type) ? 'opacity-100' : 'opacity-0',
              lastSibling ? 'h-[42%]' : 'h-[50%]',
            ].join(' ')}></div>
          {/* Middle curve */}
          <svg
            width="15"
            height="12"
            viewBox="0 0 15 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{
              color: groupLineColour,
            }}
            className={[
              'absolute top-[41%] left-[20px] pointer-events-none',
              groupLineColour ? 'brightness-150' : 'text-gray-700',
              [CUE_TYPE_CUE, CUE_TYPE_HEADING].includes(cue.type) ? 'opacity-100' : 'opacity-0',
            ].join(' ')}
          >
            <path d="M2 0C2 10 15 10 15 10" stroke="currentColor"  strokeWidth={4}/>
          </svg>

          {/* Bottom */}
          <div
            style={{
              backgroundColor: groupLineColour,
            }}
            className={[
              'h-1/2 w-[4px] absolute left-[20px]',
              groupLineColour ? 'brightness-150' : 'bg-gray-700',
              [CUE_TYPE_CUE, CUE_TYPE_HEADING].includes(cue.type) ? 'bottom-0' : '-bottom-10',
              collapsedGroups.includes(cue.id) || lastSibling || (cue.type === CUE_TYPE_GROUP && !childrenCues.length) ? 'opacity-0' : 'opacity-100',
            ].join(' ')}></div>
        </>
      }

      {/* Cue Item */}
      <div
        className={[
          'relative group flex items-stretch min-h-[84px] gap-1 w-full',
        ].join(' ')}
        draggable={draggable && !readonly}
        onDragStart={onDragStartInterceptor}
        onMouseDown={contenteditableFix}
      >
        {/* Controls */}
        <div
          className="w-7 flex-none"
        >
          <CueItemSettings
            cue={cue}
            readonly={readonly}
            onJumpToCue={jumpToCue}
            onSetNext={setNextCue}
            onAddCue={addCue}
            onDuplicateCue={duplicateCue}
            onUpdateCue={updateCue}
            onDeleteCue={deleteCue}
            parentId={parentId}
            handleItemCollapse={handleItemCollapse}
            collapsedGroups={collapsedGroups}
            setRundownColoursModalOpen={setRundownColoursModalOpen}
            cueBackgroundColours={cueBackgroundColours}
          />
        </div>
        {/* Index */}
        {[CUE_TYPE_CUE, CUE_TYPE_GROUP].includes(cue.type) && <div className='w-12 flex-none flex' style={cellStyle}>
          <CueItemIndex
            className={parentId ? 'text-sm font-semibold' : 'text-base font-semibold'}
            index={index}
            type={cue.type}
            state={timestamp?.state}
            loading={loading}
            onSetNext={setNextCue}
            onJumpToCue={jumpToCue}
          />
        </div>}
        {/* Duration / Title / Heading */}
        <div
          className={[
            'flex-none ring-2 rounded transition-all',
            cue.type === CUE_TYPE_HEADING ? 'w-[612px]' : 'w-[35rem]',
            cue.id === autoAddGroup ? 'ring-blue-500' : 'ring-transparent',
          ].join(' ')}
          style={cellStyle}
        >
          {isTypeCue ? (
            <CueItemHeader
              cue={cue}
              onUpdateCue={updateCue}
              moment={moment}
              timestamp={timestamp}
              readonly={readonly}
            />
          ) : (
            <CueItemTitle
              className="font-semibold"
              cue={cue}
              onUpdateCue={updateCue}
              readonly={readonly || cue.locked}
              collapsed={collapsedGroups.includes(cue.id)}
              childrenCues={childrenCues}
            />
          )}
        </div>
        {/* Cells */}
        {![CUE_TYPE_HEADING, CUE_TYPE_GROUP].includes(cue.type) && cueCellsMemo}
      </div>
      {/* End Time */}
      { isTypeCue && timestamp && (
        <div className="absolute -bottom-3 z-10 h-6 leading-5 flex gap-1">
          <div className={['flex gap-1', className].join(' ')}>
            {/* Controls Spacer */}
            <div className="w-7 flex-none"></div>
            {/* Spacer */}
            <div className="w-[4.5rem] flex-none"></div>
            {/* Time Display */}
            <div className="flex-grow flex items-center gap-2 text-sm py-px">
              <Tooltip disabled={!running}>
                <TooltipTrigger>
                  <TimeInput
                    className="block"
                    timezone={timezone}
                    time={timestamp.actualEnd}
                    updateTime={handleUpdateEndTime}
                  />
                </TooltipTrigger>
                <TooltipContent>
                  <p>Planned End: {formatTimeOfDay(timestamp.plannedEnd)}</p>
                  <p>Actual End: {formatTimeOfDay(timestamp.actualEnd)}</p>
                </TooltipContent>
              </Tooltip>
              {timestamp?.state === cueState.CUE_STATE_PAST && (
                <div
                  className={['font-mono', (endDelta < 0 ? 'text-red-500' : 'text-green-500')].join(' ')}
                  title={`Planned Duration: ${formatDuration(timestamp.plannedEnd)}\nActual Duration: ${formatDuration(timestamp.actualEnd)}`}
                >
                  {endDelta >= 1000 ? formatDurationHuman(endDelta) + ' earlier than planned' : ''}
                  {endDelta <= -1000 ? formatDurationHuman(endDelta) + ' later than planned' : ''}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </section>
  )
}

CueItem.propTypes = {
  rundownId: PropTypes.string.isRequired,
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  className: PropTypes.string,
  runnerId: PropTypes.string,
  timezone: PropTypes.string,
  timestamp: PropTypes.object, // undefined for type='headings'
  cue: PropTypes.object.isRequired,
  cells: PropTypes.array,
  columns: PropTypes.array.isRequired,
  hiddenColumns: PropTypes.array.isRequired,
  isNext: PropTypes.bool,
  readonly: PropTypes.bool,
  onDragStart: PropTypes.func,
  onDrop: PropTypes.func,
  onDragOver: PropTypes.func,
  dragging: PropTypes.bool,
  dragover: PropTypes.bool,
  setRundownColoursModalOpen: PropTypes.func,
  cueBackgroundColours: PropTypes.array,
  parentId: PropTypes.string,
  handleItemCollapse: PropTypes.func,
  collapsedGroups: PropTypes.array,
  lastSibling: PropTypes.bool,
  childrenCues: PropTypes.array,
  cuesTypesMap: PropTypes.object,
  draggingGroup: PropTypes.bool,
  parentBackgroundColour: PropTypes.string,
  autoAddGroup: PropTypes.string,
  running: PropTypes.bool,
}

function _mouseInside (target, event) {
  var rect = target.getBoundingClientRect()
  if (event.clientX <= rect.left || rect.right < event.clientX) return false
  if (event.clientY <= rect.top || rect.bottom < event.clientY) return false
  return true
}
